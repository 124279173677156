.modal {
  &__top {
    background-color: #868686 !important;
    padding: 16px !important;
  }
  &__title {
    min-height: auto !important;
    height: auto !important;
    padding: 0 !important;
  }
  &__content {
    padding: 16px !important;
  }
  .MuiPaper-rounded {
    border-radius: 8px !important;
    width: calc(100% - 24px);
    margin: 0;
  }
}