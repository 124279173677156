.user-auth {
  &__btn {
    padding: 0 !important;
  }
  &__avatar {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  &__menu-link {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.54);
  }
}
#user-auth {
  .MuiMenu-paper {
    margin-top: 8px;
  }
  .MuiMenuItem-root {
    color: rgba(0, 0, 0, 0.54);
  }
  .MuiListItemIcon-root {
    min-width: 40px;
  }
}