.sidebar__navigation {
  .navigation {
    padding-right: 16px;
    &__item {
      color: #fff;
      padding: 0;
      .MuiListItem-root {
        padding: 0;
        .navigation__text {
          padding-left: 32px;
        }
      }
    }
    &__link, &__dropdown {
      text-decoration: none !important;
      display: flex;
      align-items: center;
      color: #fff;
      width: 100%;
      border-radius: 0 20px 20px 0;
      padding: 6px 12px 5px 24px;
      cursor: pointer;
      transition: all .2s linear;
      &:hover {
        background-color: rgba(255, 255, 255, 0.08);
      }
      &.active {
        color: rgba(0, 0, 0, 0.87) !important;
        pointer-events: none;
        background-color: #FECE00 !important;
      }
      .MuiIcon-root {
        font-size: 16px;
        margin-right: 16px;
      }
      .MuiBadge-badge {
        position: static;
        transform: none;
      }
    }
    &__dropdown._open {
      color: #FECE00;
    }
    &__text span {
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}