.sidebar {
  width: 64px;
  min-width: 64px;
  height: 100%;
  position: relative;
  &._pinned {
    width: 280px;
    min-width: 280px;
    .sidebar__content {
      position: static;
      width: 280px;
    }
    //Показать скрытые элементы
    .sidebar__user-name,
    .sidebar__user-email {
      visibility: visible;
    }
    .sidebar__user-avatar {
      width: 80px;
      height: 80px;
      padding: 8px;
      bottom: 0;
      transform: translate(-50%, 50%);
    }
    .sidebar__navigation .navigation {
      padding-left: 0;
      padding-right: 16px;
    }
    .sidebar__navigation .navigation__link,
    .sidebar__navigation .navigation__dropdown {
      height: 40px;
      border-radius: 0 20px 20px 0;
      padding-right: 12px;
      padding-left: 24px;
      justify-content: flex-start;
      .navigation__icon {
        margin-right: 16px;
      }
      .navigation__text, .MuiBadge-root, .MuiSvgIcon-root {
        display: block;
      }
    }
    .MuiCollapse-container {
      display: block;
    }
  }
  &__content {
    background-color: #121212;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    width: 64px;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    transition: width .2s ease-in-out;
    &:hover {
      width: 280px;
      //Показать скрытые элементы
      .sidebar__user-name,
      .sidebar__user-email {
        visibility: visible;
      }
      .sidebar__user-avatar {
        width: 80px;
        height: 80px;
        padding: 8px;
        bottom: 0;
        transform: translate(-50%, 50%);
      }
      .sidebar__navigation .navigation {
        padding-left: 0;
        padding-right: 16px;
      }
      .sidebar__navigation .navigation__link,
      .sidebar__navigation .navigation__dropdown {
        height: 40px;
        border-radius: 0 20px 20px 0;
        padding-right: 12px;
        padding-left: 24px;
        justify-content: flex-start;
        .navigation__icon {
          margin-right: 16px;
        }
        .navigation__text, .MuiBadge-root, .MuiSvgIcon-root {
          display: block;
        }
      }
      .MuiCollapse-container {
        display: block;
      }
    }
  }
  &__head {
    height: 64px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #555;
    color: #fff;
    position: sticky;
    top: 0;
  }
  &__logo {
    display: block;
    width: 40px;
  }
  &__pin-btn {
    &._pinned span {
      transform: rotate(0);
    }
    span {
      color: #fff;
      transform: rotate(18deg);
      transition: all .2s linear;
    }
  }
  &__close-btn {
    span {
      color: #fff;
    }
  }
  &__scroll {
    height: calc(100% - 64px);
  }
  &__user {
    background-color: #555;
    padding: 24px 12px 64px;
    margin-bottom: 32px;
    color: #fff;
    text-align: center;
    position: relative;
    &-name {
      visibility: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: default;
    }
    &-email {
      visibility: hidden;
      font-size: 14px;
      line-height: 20px;
      opacity: .5;
      margin-top: 8px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: default;
    }
    &-avatar {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #121212;
      overflow: hidden;
      position: absolute;
      left: 50%;
      bottom: 50%;
      transform: translate(-50%, 50%);
      transition: all .15s ease-in-out;
    }
  }
  .sidebar__navigation .navigation {
    padding-left: 12px;
    padding-right: 12px;
  }
  .sidebar__navigation .navigation__link,
  .sidebar__navigation .navigation__dropdown {
    height: 40px;
    border-radius: 50%;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
    .navigation__icon {
      margin-right: 0;
    }
    .navigation__text, .MuiBadge-root, .MuiSvgIcon-root {
      display: none;
    }
  }
  .MuiCollapse-container {
    display: none;
  }
}

.sidebar-mobile {
  .sidebar__content {
    position: static;
    width: 280px;
  }
  .sidebar__user-name,
  .sidebar__user-email {
    visibility: visible;
  }
  .sidebar__user-avatar {
    width: 80px;
    height: 80px;
    padding: 8px;
    bottom: 0;
    transform: translate(-50%, 50%);
  }
  .sidebar__navigation .navigation {
    padding-left: 0;
    padding-right: 16px;
  }
  .sidebar__navigation .navigation__link,
  .sidebar__navigation .navigation__dropdown {
    height: 40px;
    border-radius: 0 20px 20px 0;
    padding-right: 12px;
    padding-left: 24px;
    justify-content: flex-start;
    .navigation__icon {
      margin-right: 16px;
    }
    .navigation__text, .MuiBadge-root, .MuiSvgIcon-root {
      display: block;
    }
  }
  .MuiCollapse-container {
    display: block;
  }
}