.header {
  height: 64px;
  min-height: 64px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  @media (max-width: 1100px) {
    padding-left: 12px;
    justify-content: space-between;
  }
  @media (max-width: 400px) {
    padding-left: 0;
    padding-right: 12px;
  }
  .MuiIconButton-root:not(:last-child) {
    padding: 10px;
    @media (max-width: 400px) {
      padding: 10px !important;
      width: 40px;
      height: 40px;
    } 
  }
  &__left {
    margin-left: auto;
    padding: 12px 0 12px 12px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__icon-section {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    @media (max-width: 500px) {
      width: 40px;
      height: 40px;
    }
  }
  &__button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__icons {
    padding: 0 !important;
    width: 40px;
    height: 40px;
    .MuiIcon-root {
      @media (max-width: 650px) {
        font-size: 21px;
      } 
    }
    &__title {
      font-size: 12px;
      text-align: center;
      @media (max-width: 900px) {
        display: none;
      }
    }  
  }
} 