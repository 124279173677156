.custom-button {
  background-color: #FECE00 !important;
  color: #000 !important;
  text-transform: none !important;
  font-family: sans-serif !important;
  box-shadow: none !important;
  &:hover {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12) !important;
  }
  &.Mui-disabled {
    color: #868686 !important;
    background-color: #FFE98C !important;
    box-shadow: none !important;
  }
  &._grey {
    background-color: #E0E0E0 !important;
  }
  &._red {
    background-color: #FF2800 !important;
    color: #fff !important;
    &.Mui-disabled {
      background-color: #FF9581 !important;
    }
  }
  &._outline {
    background-color: #FFF !important;
    border: 2px solid #FECE00;
    &.Mui-disabled {
      border-color: #FFE98C !important;
    }
  }
}