.auth {
  height: 100vh;
}
.auth-layout {
  min-height: 100%;
  background: linear-gradient(to left, #122230 0%, rgb(9, 17, 24) 100%);
  display: flex;
  justify-content: center;
  padding: 24px;
  &__content {
    width: 100%;
    max-width: 1280px;
    display: flex;
    border-radius: 12px;
    box-shadow: 0 25px 50px -12px rgba(0,0,0,0.25);
    overflow: hidden;
    @media (max-width: 760px) {
      justify-content: center;
      border-radius: 0;
    }
    &-left {
      width: 100%;
      max-width: 480px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 64px 32px;
      @media (max-width: 1060px) {
        width: 50%;
        min-width: 376px;
      }
      @media (max-width: 760px) {
        width: 100%;
        min-width: auto;
        border-radius: 12px;
      }
      @media (max-width: 530px) {
        padding: 48px 24px;
      }
      h2 {
        text-align: center;
        margin-bottom: 32px;
      }
    }
    &-right {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 64px;
      color: #fff;
      @media (max-width: 1060px) {
        padding: 32px;
      }
      @media (max-width: 760px) {
        display: none;
      }
    }
  }
  &__title {
    max-width: 480px;
    font-size: 48px;
    line-height: 1.25;
    text-align: center;
    @media (max-width: 1060px) {
      font-size: 32px;
    }
  }
  &__subtitle {
    max-width: 480px;
    line-height: 1.75;
    margin-top: 32px;
    text-align: center;
  }
  .submit-button {
    display: block;
    margin: 0 auto;
  }
}