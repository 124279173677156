* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*:before,
*:after {
    box-sizing: border-box;
}

#root {
    height: 100%;
}

html {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif; 
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100%;
}

body {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    font-style: normal;
    color: #000;
    height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden],
template {
    display: none;
}
a {
    background: transparent;
    outline: none;
    text-decoration: none;
    color: #3366ff;
    transition: all .2s linear;
    &:hover {
        text-decoration: underline;
    }
}
a:active,
a:hover {
    outline: 0;
}
abbr[title] {
    border-bottom: 1px dotted;
}
b,
strong {
    font-weight: bold;
}
dfn {
    font-style: italic;
}
mark {
    color: #000;
    background: #ff0;
}
small {
    font-size: 80%;
}
sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}
sup {
    top: -.5em;
}
sub {
    bottom: -.25em;
}
img {
    border: 0;
}
svg:not(:root) {
    overflow: hidden;
}
hr {
    height: 0;
    box-sizing: content-box;
}
pre {
    overflow: auto;
}
code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font: inherit;
    color: inherit;
    filter: none;
}
button {
    overflow: visible;
    outline: none;
    border: none;
    background: transparent;
}
button,
select {
    text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}
input {
    line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
input[type="search"] {
    box-sizing: content-box;
    -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
fieldset {
    padding: 0.35em 0.625em 0.75em;
    margin: 0 2px;
    border: 1px solid #c0c0c0;
}
legend {
    padding: 0;
    border: 0;
}
input,
textarea {
    outline: none;
}
textarea {
    overflow: auto;
}
optgroup {
    font-weight: bold;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
td,
th {
    padding: 0;
}
.MuiPickersToolbar-toolbar {
    background-color: #192d3e !important;
}
@media (max-width:450px) {
  .MuiAutocomplete-popper div {
    font-size: 12px !important;
  }
}