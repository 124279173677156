.user-layout {
  height: 100%;
  display: flex;
  &__main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  &__content {
    flex: 1 1 auto;
    background-color: #f6f7f9;
  }
}