.custom-confirm {
  &__content {
    padding-bottom: 18px;
  }
  &__controls {
    padding: 8px 24px 16px;
    justify-content: center !important;
  }
  &__top {
    background-color: #868686 !important;
    padding: 16px !important;
  }
  &__title {
    min-height: auto !important;
    padding: 0 !important;
  }
  .MuiButton-label {
    text-transform: none;
  }
  .MuiButton-root {
    font-family: sans-serif !important;
  }
  .MuiPaper-rounded {
    border-radius: 8px !important;
  }
}