.edit-avatar {
  overflow: hidden;
  &:hover {
    .edit-avatar__icon-wrap {
      transform: translateY(0);
    }
  }
  .edit-avatar__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .edit-avatar__icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    color: #fff;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateY(100%);
    transition: all .2s linear;
  }
}

.avatar-editor {
  min-width: 180px;
  margin: 0 auto;
  &__dropzone {
    width: 350px;
    margin: 0 auto;
    position: relative;
    @media (max-width: 600px) {
      width: 180px;
    }
    & > * {
      outline: none;
    }
    canvas {
      @media (max-width: 600px) {
        width: 180px !important;
        height:180px !important;
      }
    }
  }
  &__add-file {
    display: inline-block;
    position: absolute;
    right: -20px;
    bottom: -14px;
    z-index: 1;
  }
  &__add-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #000;
    background-color: #FECE00;
    cursor: pointer;
    box-shadow: 0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12);
    transition: background-color .2s linear;
  }
  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 350px;
    margin: 24px auto 0;
    padding-left: 21px;
    @media (max-width: 600px) {
      max-width: 220px;
    }
  }
  &__scale-bar {
    margin-right: 21px;
    color: #FECE00;
  }
  &__buttons {
    display: flex;
    justify-content: center;
  }
  &__submit,
  &__delete {
    display: block !important;
    margin: 24px 0 0 0 !important;
  }
  &__submit {
    margin-right: 8px !important;
  }
}